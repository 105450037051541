import dayjs from 'dayjs';
import isequal from 'lodash.isequal';

import { deepClone } from 'other/helpers';
import { ENDPOINTS } from 'other/config';
import { GA } from 'services/analytics';
import { http } from 'services/http';
import { initialMapAreas } from './mapOptionsInitialState';
import { isEnvLayer, isWindyLayer } from 'pages/Map2/utils/helpers';
import { SettingsService } from 'services/settings';

import {
  EMapArea,
  EMapAreaAction,
  EMapLayer,
  EUserAuthority,
  TDateListItem,
  TLayerInfo,
  TMapAreas
} from 'types';
import { EMapOptionsActions, fetchInfoSet } from './mapOptionsConstants';
import { TAction } from '../../_utils/reducerCreator';
import { TMapOptionsState } from './mapOptionsModel';
import { TState } from '../../appStateModel';

/**/
const DAYS_BEFORE = 3; // How many earlier days we want to have in the scale.

/**
 *
 * @param options
 */
export function updateMapOptionsAction(options: Partial<TMapOptionsState>) {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    const update = {
      ...mapOptions,
      ...options
    };
    const action: TAction<TMapOptionsState, EMapOptionsActions> = {
      type: EMapOptionsActions.MAP_SET_OPTIONS,
      payload: update
    };

    SettingsService.writeSettings({ [SettingsService.MAP_SETTINGS]: update });
    dispatch(action);

    if ('layer' in options) {
      GA.reportLayerChange(options.layer);
    }
  };
}

/**
 *
 */
export function checkMapOptions() {
  return (dispatch, getState) => {
    const { mapOptions, session } = getState() as TState;
    const permissions = session.user?.userInfo?.authorities || [];

    if (
      !permissions.includes(EUserAuthority.VIEW_MAP_ICE_LAYER) &&
      mapOptions.layer === EMapLayer.ICE
    ) {
      dispatch(updateMapOptionsAction({ layer: EMapLayer.EEZ }));
    }
  };
}

/**
 *
 */
export function changeMapAreaAction(area: EMapArea, action: EMapAreaAction) {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    const mapAreas = handleAreas(mapOptions.mapAreas, area, action);

    window.console.log(
      ' area:',
      area,
      '\n',
      'action:',
      action,
      '\n',
      JSON.stringify(mapAreas, null, 2)
    );

    dispatch(updateMapOptionsAction({ mapAreas }));
  };
}

/**
 *
 */
export function handleAreas(
  mapAreas: Readonly<TMapAreas>,
  area: EMapArea,
  action: EMapAreaAction
): TMapAreas {
  const areas = deepClone(mapAreas);
  let update = null;

  if (area === EMapArea.ICES) {
    /**
     * ICES
     */
    const top =
      action === EMapAreaAction.ADD
        ? [...areas.top, area]
        : removeArea(areas.top, area);
    update = checkEEZ(checkTop(areas, { top } as any));
  } else if (area === EMapArea.EEZ_LINES) {
    /**
     * EEZ_LINES
     */
    if (action === EMapAreaAction.ADD) {
      if (isOnlyOSM(areas)) {
        update = { feature: EMapArea.EEZ_AREAS };
      } else {
        update = {
          top: [...areas.top, area]
        };
      }
    } else {
      if (isInitial(areas)) {
        update = { feature: null };
      } else {
        update = { top: removeArea(areas.top, area) };
      }
    }
    update = checkTop(areas, update);
  } else if (area === EMapArea.SEABED_BW) {
    /**
     * SEABED_BW
     */
    if (action === EMapAreaAction.ADD) {
      update = { base: EMapArea.SEABED_BW };
      if (areas.feature === EMapArea.EEZ_AREAS) {
        update = {
          base: EMapArea.SEABED_BW,
          feature: null,
          top: [...areas.top, EMapArea.EEZ_LINES]
        };
      }
    } else if (action === EMapAreaAction.REMOVE) {
      update = { base: EMapArea.OSM };
    } else {
      update = { base: EMapArea.SEABED_COLOUR };
    }
    update = checkEEZ(checkTop(areas, update));
  } else if (area === EMapArea.SEABED_COLOUR) {
    /**
     * SEABED_COLOUR
     */
    if (action === EMapAreaAction.ADD) {
      update = { base: EMapArea.SEABED_COLOUR };
    } else if (action === EMapAreaAction.REMOVE) {
      update = { base: EMapArea.OSM };
    } else {
      update = {
        base:
          areas.base === EMapArea.SEABED_BW
            ? EMapArea.SEABED_COLOUR
            : EMapArea.SEABED_BW
      };
    }
    update = checkEEZ(checkTop(areas, update));
  } else if (area === EMapArea.DEPTH_CURVES_BW) {
    /**
     * DEPTH_CURVES_BW
     */
    if (action === EMapAreaAction.ADD) {
      update = { top: [...areas.top, EMapArea.DEPTH_CURVES_BW] };
      if (areas.feature === EMapArea.EEZ_AREAS) {
        update = {
          feature: null,
          top: [EMapArea.DEPTH_CURVES_BW, EMapArea.EEZ_LINES]
        };
      }
    } /*if (action === EMapAreaAction.REMOVE)*/ else {
      // Remove
      update = {
        feature: null,
        top: removeArea(areas.top, area)
      };
      // if (update.top.length === 0 && !mapAreas.feature) {
      //   update = {
      //     base: EMapArea.OSM,
      //     feature: EMapArea.EEZ_AREAS,
      //     top: []
      //   };
      // }
    }
    // else {
    // toggle is not a case!
    // update = {
    //   feature: EMapArea.DEPTH_CURVES_COLOUR,
    //   top: removeArea(areas.top, area)
    // };
    // }
    update = checkEEZ(checkTop(areas, update));
  } else if (area === EMapArea.DEPTH_CURVES_COLOUR) {
    /**
     * DEPTH_CURVES_COLOUR
     */
    if (action === EMapAreaAction.ADD) {
      update = { feature: EMapArea.DEPTH_CURVES_COLOUR };
      if (areas.feature === EMapArea.EEZ_AREAS) {
        update = {
          ...update,
          top: [...areas.top, EMapArea.EEZ_LINES]
        };
      }
    } else if (action === EMapAreaAction.REMOVE) {
      update = { feature: null };
    } else {
      update =
        areas.feature === EMapArea.DEPTH_CURVES_COLOUR
          ? {
              feature: null,
              top: [...areas.top, EMapArea.DEPTH_CURVES_BW]
            }
          : {
              feature: EMapArea.DEPTH_CURVES_COLOUR,
              top: removeArea(areas.top, EMapArea.DEPTH_CURVES_BW)
            };
    }
    update = checkEEZ(checkTop(areas, update));
  }

  return update;
}

/**
 *
 */
function checkTop(areas: TMapAreas, _update: Partial<TMapAreas>): TMapAreas {
  const update = { ...areas, ..._update };
  if (
    Array.isArray(update.base) ||
    Array.isArray(update.feature) ||
    !Array.isArray(update.top)
  ) {
    window.console.error('Bad data type');
  }

  return {
    base: update.base,
    feature: update.feature,
    top: Array.from(new Set(update.top)) as any
  };
}

/**
 *
 */
function checkEEZ(areas: TMapAreas): TMapAreas {
  let result = areas;

  const isIncomplete =
    isequal(areas, {
      base: EMapArea.OSM,
      feature: null,
      top: [EMapArea.EEZ_LINES]
    }) ||
    isequal(areas, {
      base: EMapArea.OSM,
      feature: null,
      top: []
    });

  result = isIncomplete ? initialMapAreas : areas;

  if (
    (result.base !== EMapArea.OSM &&
      areas.feature === EMapArea.EEZ_AREAS &&
      !areas.top.includes(EMapArea.EEZ_LINES)) ||
    (areas.feature === EMapArea.EEZ_AREAS &&
      !areas.top.includes(EMapArea.EEZ_LINES) &&
      areas.top.length > 0)
  ) {
    result = {
      ...result,
      feature: null,
      top: [...result.top, EMapArea.EEZ_LINES] as any
    };
  }

  return result;
}

/**
 *
 */
function isInitial(areas: TMapAreas): boolean {
  return isequal(areas, initialMapAreas);
}

/**
 *
 */
function isOnlyOSM(areas: TMapAreas): boolean {
  return isequal(areas, {
    base: EMapArea.OSM,
    feature: null,
    top: []
  });
}

/**
 *
 */
export function handleAreas2(
  areas: EMapArea[],
  area: EMapArea,
  shouldToggle?: boolean
): EMapArea[] {
  let mapAreas = [...areas];

  if (area === EMapArea.EEZ_AREAS || area === EMapArea.EEZ_LINES) {
    // Removing EEZ
    if (
      areas.includes(EMapArea.EEZ_AREAS) ||
      areas.includes(EMapArea.EEZ_LINES)
    ) {
      mapAreas = removeArea(
        removeArea(mapAreas, EMapArea.EEZ_AREAS),
        EMapArea.EEZ_LINES
      );
    } else {
      // Adding EEZ
      mapAreas =
        mapAreas.length === 0
          ? [EMapArea.EEZ_AREAS]
          : [EMapArea.EEZ_LINES, ...mapAreas];
    }
  } else {
    if (shouldToggle) {
      mapAreas = [...removeSimilarAreas(mapAreas, area), area];
    } else if (areas.includes(area)) {
      // Removing non-EEZ area
      mapAreas = removeArea(mapAreas, area);

      mapAreas =
        mapAreas.length === 0 ||
        (mapAreas.length === 1 && mapAreas.includes(EMapArea.EEZ_LINES))
          ? [EMapArea.EEZ_AREAS]
          : mapAreas;
    } else {
      // Adding non-EEZ area
      if (areas.includes(EMapArea.EEZ_AREAS)) {
        mapAreas = [
          area,
          EMapArea.EEZ_LINES,
          ...removeArea(mapAreas, EMapArea.EEZ_AREAS)
        ];
      } else {
        mapAreas = [area, ...mapAreas];
      }
    }
  }

  return mapAreas;
}

/**
 *
 */
function removeSimilarAreas(areas: EMapArea[], area: EMapArea): EMapArea[] {
  return areas.filter((a: EMapArea) => !a.startsWith(area.slice(0, 4)));
}

/**
 *
 */
function removeArea(areas: EMapArea[], area: EMapArea): EMapArea[] {
  return areas.filter((a: EMapArea) => a !== area);
}

/**
 *
 */
export function resetMapAreasAction() {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    const mapAreas =
      isEnvLayer(mapOptions.layer) || isWindyLayer(mapOptions.layer)
        ? [EMapArea.EEZ_LINES]
        : [EMapArea.EEZ_AREAS];

    // dispatch(updateMapOptionsAction({ mapAreas }));
  };
}

/**
 *
 */
export function fetchLayerInfoAction() {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    if (Object.keys(mapOptions.layerInfo).length > 0 || mapOptions.isPending) {
      return;
    }

    dispatch(fetchInfoSet.request());

    http
      .send(ENDPOINTS.LAYER_INFO)
      .then((r: Record<number, TLayerInfoSimple>) => {
        const layerInfo = handleInfo(r);

        dispatch(
          fetchInfoSet.success({
            layerDateLabel: getSelectedDate(
              layerInfo[mapOptions.layer].dateList
            ),
            layerInfo: layerInfo
          })
        );
      })
      .catch((e) => dispatch(fetchInfoSet.error(e)));
  };
}

/**/
type TLayerInfoSimple = Omit<TLayerInfo, 'dateList'> & {
  dateList: string[];
};

/**
 *
 */
function getSelectedDate(list: TDateListItem[]): string {
  if (!Array.isArray(list) || list.length === 0) return;

  return list.length < DAYS_BEFORE + 1
    ? list[list.length - 1].label
    : list[DAYS_BEFORE].label;
}

/**
 *
 */
function handleInfo(
  info: Record<number, TLayerInfoSimple>
): Record<EMapLayer, TLayerInfo> {
  const data = {};
  Object.values(info).forEach((v: TLayerInfoSimple) => v && (data[v.name] = v));

  return {
    [EMapLayer.ICE_COVERAGE]: processDates(data['IceCoverageSmooth']),
    [EMapLayer.PHYTOPLANKTON]: processDates(data['PlanktonSmooth']),
    [EMapLayer.SALINITY]: processDates(data['SalinitySmooth']),
    [EMapLayer.SEATEMP]: processDates(data['SeaTemperatureSmooth']),
    [EMapLayer.SURFACETEMP]: processDates(data['SeaTemperatureSmooth']),
    [EMapLayer.UPWELLING]: processDates(data['UpwellingSmooth']),
    [EMapLayer.ZOOPLANKTON]: processDates(data['ZooplanktonSmooth'])
  } as any;
}

/**
 *
 */
function processDates(info: TLayerInfoSimple): TLayerInfo {
  if (!info) return {} as any;

  const fourDaysBefore = dayjs().subtract(DAYS_BEFORE + 1, 'days');

  const dateList = info.dateList
    .filter((d: string) => dayjs(d).isAfter(fourDaysBefore))
    .slice(0, 8)
    .map((date: string, idx: number, arr: string[]) => ({
      dateTime: date,
      label: convertToDateLabel(date, idx, arr)
    }));

  return { ...info, dateList };
}

/**
 *
 */
function convertToDateLabel(date: string, idx: number, arr: string[]): string {
  if (idx === 0) return dayjs(date).format('MMM DD');

  // Current date is of same date as previous;
  const isSameDate =
    dayjs(date).get('date') === dayjs(arr[idx - 1]).get('date');

  return isSameDate
    ? dayjs(date).clone().add(1, 'day').format('MMM DD')
    : dayjs(date).format('MMM DD');
}
