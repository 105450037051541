import React from 'react';
import message from 'antd/lib/message';

import { IconCustom } from '../../../components/common/IconCustom/IconCustom';
import { EMapLayer } from '../../../types';

/* eslint-disable */
export default function convertDDToDMS(D, lng, minPrecision = 10000) {
  return {
    dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
    deg: 0 | (D < 0 ? (D = -D) : D),
    min: (0 | ((D % 1) * 60 * minPrecision)) / minPrecision,
    sec: (0 | (((D * 60) % 1) * 6000)) / 100
  };
}

/**
 *
 * @param D
 * @param minPrecision
 * @return {{sec: number, min: number, deg: number, dir: (string)}}
 */
export function convertLatDD2DMS(D, minPrecision = 10000) {
  return {
    dir: D < 0 ? 'S' : 'N',
    deg: 0 | (D < 0 ? (D = -D) : D),
    ...baseConvertor(D, minPrecision)
  };
}

/**
 *
 * @param D
 * @param minPrecision
 * @return {{sec: number, min: number, deg: number, dir: (string)}}
 */
export function convertLngDD2DMS(D, minPrecision = 10000) {
  let x = D;
  let dir = 'E';

  if (D < -180) {
    x = D + 360;
  } else if (D > 180) {
    dir = 'W';
    x = 360 - D;
  } else if (D < 0) {
    dir = 'W';
    x = Math.abs(D);
  }

  return {
    dir: dir,
    deg: 0 | x,
    ...baseConvertor(x, minPrecision)
  };
}

/**
 *
 * @param D
 * @param minPrecision
 * @return {{sec: number, min: number, deg: number}}
 */
export function baseConvertor(D, minPrecision) {
  return {
    min: (0 | ((D % 1) * 60 * minPrecision)) / minPrecision,
    sec: (0 | (((D * 60) % 1) * 6000)) / 100
  };
}

/**/
export function isWindyLayer(layer) {
  return (
    layer === EMapLayer.CURR ||
    layer === EMapLayer.GUSTS ||
    layer === EMapLayer.TEMP ||
    layer === EMapLayer.WIND ||
    layer === EMapLayer.WAVES
  );
}

/**
 *
 */
export function isEnvLayer(layer) {
  return (
    layer === EMapLayer.ICE ||
    layer === EMapLayer.ICE_COVERAGE ||
    layer === EMapLayer.PHYTOPLANKTON ||
    layer === EMapLayer.SALINITY ||
    layer === EMapLayer.SEATEMP ||
    layer === EMapLayer.SURFACETEMP ||
    layer === EMapLayer.UPWELLING ||
    layer === EMapLayer.ZOOPLANKTON
  );
}

/**/
export const LEGEND_CONTAINER = 'LEGEND_CONTAINER';

/**/
export function showTileLoader(text, duration, options = {}) {
  message.open({
    duration: duration,
    className: 'TileLoader',
    content: (
      <>
        <IconCustom className="IceMap__spinner" type="loading" />
        <span>{text}</span>
      </>
    ),
    style: options.style || {}
  });
}
